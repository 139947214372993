import React, { useState, createContext } from 'react'

export const DisciplineContext = createContext();

export const DisciplineProvider = (props) => {
  const [disciplines, setDisciplines] = useState([
    {
      name: "KW 25m Kombi",
      selected: true,
      id: 1
    },
    {
      name: "LW Zeitserie",
      selected: false,
      id: 2
    },
    {
      name: "LW Fertigkeit",
      selected: false,
      id: 3
    },
    {
      name: "LW Präzision",
      selected: false,
      id: 4
    }
  ])

  const updateCurrentDiscipline = id => {
    setDisciplines(prevDisciplines => {
      // set active discipline
      return prevDisciplines.map(pd => {
          return {...pd, selected: (pd.id === id)}
        }
      )
    })
  }

  return (
    <DisciplineContext.Provider value={[disciplines, updateCurrentDiscipline]}>
      { props.children }
    </DisciplineContext.Provider>
  )
}

