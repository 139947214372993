import React, { useContext, useState } from 'react'
import Sound from 'react-sound';
import fireCommand from '../../assets/beep-02.wav'
import stopCommand from '../../assets/beep-05.wav'
import SeriesItem from '../SeriesItem'
import { DisciplineContext } from '../../contexts/DisciplineContext'


export const preparationSeriesSequence = [
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 180000 },
]

export const timeSeriesSequence20 = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 27000 },
]
export const timeSeriesSequence30 = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 37000 },
]
export const timeSeriesSequence40 = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 47000 },
]

export const seriesTypes = {
  preparationSeries: "preparationSeries",
  timeSeries20: "timeSeries20",
  timeSeries30: "timeSeries30",
  timeSeries40: "timeSeries40"
}

export const seriesSequences = {
  preparationSeries: preparationSeriesSequence,
  timeSeries20: timeSeriesSequence20,
  timeSeries30: timeSeriesSequence30,
  timeSeries40: timeSeriesSequence40
}

export const BdsLwZeitserie = ({ startCalls, stopCalls }) => {
  const [disciplines,] = useContext(DisciplineContext)
  const [seriesId, setSeriesId] = useState(null)
  const discipline = disciplines[1]
  const isOngoing = (id) => seriesId === id

  if (!discipline.selected) {
    return null
  }

  const handlePlayClicked = (seriesType, id) => {
    console.log(seriesType, id)
    // start timer sequence
    const sequence = seriesSequences[seriesType]
    // update view state if sequence has been started
    if (startCalls(sequence)) {
      setSeriesId(id)
    }
  }

  const handleStopClicked = () => {
    // stop call sequence
    stopCalls()
    // reset series id => nothing playing right now
    setSeriesId(null)
  }

  return (
    <>
      <SeriesItem
        id={0}
        playClicked={(id) => handlePlayClicked(seriesTypes.preparationSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(0)}
        name={"Probeserie (3m)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 180) {
              return "FEUER"
            } else if (elapsedTime >= 180) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 0 && elapsedTime <= 180) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={1}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries40, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(1)}
        name={"Zeitserie 1 (40s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 47) {
              return "FEUER"
            } else if (elapsedTime >= 47) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 47) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={2}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries40, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(2)}
        name={"Zeitserie 2 (40s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 47) {
              return "FEUER"
            } else if (elapsedTime >= 47) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 47) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={3}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries30, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(3)}
        name={"Zeitserie 3 (30s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 37) {
              return "FEUER"
            } else if (elapsedTime >= 37) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 37) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={4}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries30, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(4)}
        name={"Zeitserie 4 (30s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 37) {
              return "FEUER"
            } else if (elapsedTime >= 37) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 37) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={5}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries20, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(5)}
        name={"Zeitserie 5 (20s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 27) {
              return "FEUER"
            } else if (elapsedTime >= 27) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 27) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={6}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries20, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(6)}
        name={"Zeitserie 6 (20s)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 27) {
              return "FEUER"
            } else if (elapsedTime >= 27) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 7 && elapsedTime <= 27) {
            return "green"
          } else {
            return "red"
          }
        })} />
    </>
  );
};

export default BdsLwZeitserie
