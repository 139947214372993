import { createGlobalStyle } from 'styled-components'

// http://bootstrap.themes.guide/greyson/theme.css
//
//   --blue: #007bff;
//   --indigo: #6610f2;
//   --purple: #6f42c1;
//   --pink: #e83e8c;
//   --red: #dc3545;
//   --orange: #fd7e14;
//   --yellow: #ffc107;
//   --green: #28a745;
//   --teal: #20c997;
//   --cyan: #17a2b8;
//   --white: #fff;
//   --gray: #6c757d;
//   --gray-dark: #343a40;
//   --primary: #2f3c48;
//   --secondary: #6f7f8c;
//   --success: #3e4d59;
//   --info: #5c8f94;
//   --warning: #6e9fa5;
//   --danger: #cc330d;
//   --light: #eceeec;
//   --dark: #1e2b37;

const primary = '#2f3c48'
const secondary = '#6f7f8c'

const defaultTheme = {
  colors: {
    primary,
    secondary,
    bgNavbar: '#20232A',
    txtNavbar: '#FFFFFF',
    bgPrimary: '#C0DFA1',
    bgSecondary: '#343A40',
    text: '#000',
    gray: '#979797',
    inactive: '#979797',
    active: '#61DAFB',
    background: '#FFFFFF',
    alert: '#F90505',
    brandGradient: `linear-gradient(225deg, ${primary} 0%, ${secondary} 100%)`,
    bgPlayButton: '#C0DFA1',
    bgStopButton: '#dc3545',
    bgFooter: '#20232A',
    txtFooter: '#FFFFFF',
    bgDisciplineChooser: '#282C34',
    bgDisciplineItem: '#FFFFFF'
  },
  geometry: {
    footerHeight: '5rem'
  }
}

export const theme = defaultTheme

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Asap:400,700');

  * {
    box-sizing: border-box;
  }

  html {
    font-family: 'Asap', sans-serif;
  }

  html, body, main {
    height: 100%;
    margin: 0;
  }
`
