import React, { useContext, useState } from 'react'
import Sound from 'react-sound';
import fireCommand from '../../assets/beep-02.wav'
import stopCommand from '../../assets/beep-05.wav'
import SeriesItem from '../SeriesItem'
import { DisciplineContext } from '../../contexts/DisciplineContext'


export const preparationSeriesSequence = [
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 180000 },
]

export const precisionSeriesSequence = [
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 300000 },
]

export const timeSeriesSequence10 = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 17000 },
]

export const timeSeriesSequence20 = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 27000 },
]

export const intervalSequence = [
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 7000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 10000 },
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 17000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 20000 },
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 27000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 30000 },
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 37000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 40000 },
  { playStatus: Sound.status.PLAYING, command: fireCommand, delay: 47000 },
  { playStatus: Sound.status.PLAYING, command: stopCommand, delay: 50000 }
]

export const seriesTypes = {
  preparationSeries: "preparationSeries",
  precisionSeries: "precisionSeries",
  intervalSeries: "intervalSeries",
  timeSeries20: "timeSeries20",
  timeSeries10: "timeSeries10"
}

export const seriesSequences = {
  preparationSeries: preparationSeriesSequence,
  precisionSeries: precisionSeriesSequence,
  intervalSeries: intervalSequence,
  timeSeries20: timeSeriesSequence20,
  timeSeries10: timeSeriesSequence10
}



export const BdsKombi = ({startCalls, stopCalls}) => {
  const [disciplines,] = useContext(DisciplineContext)
  const [seriesId, setSeriesId] = useState(null)
  const discipline = disciplines[0]
  const isOngoing = (id) => seriesId === id

  if (!discipline.selected) {
    return null
  }

  const handlePlayClicked = (seriesType, id) => {
    console.log(seriesType, id)
    // start timer sequence
    const sequence = seriesSequences[seriesType]
    // update view state if sequence has been started
    if (startCalls(sequence)) {
      setSeriesId(id)
    }
  }

  const handleStopClicked = () => {
    // stop call sequence
    stopCalls()
    // reset series id => nothing playing right now
    setSeriesId(null)
  }

  return (
    <>
      <SeriesItem
        id={0}
        playClicked={(id) => handlePlayClicked(seriesTypes.preparationSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(0)}
        name={"Probeserie (3m)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 180) {
              return "FEUER"
            } else if (elapsedTime >= 180) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 0 && elapsedTime <= 180) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={1}
        playClicked={(id) => handlePlayClicked(seriesTypes.precisionSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(1)}
        name={"Präzisionsserie 1 (5m)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 300) {
              return "FEUER"
            } else if (elapsedTime >= 300) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 0 && elapsedTime <= 300) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={2}
        playClicked={(id) => handlePlayClicked(seriesTypes.precisionSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(2)}
        name={"Präzisionsserie 2 (5m)"}
        showTime
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 300) {
              return "FEUER"
            } else if (elapsedTime >= 300) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime >= 0 && elapsedTime <= 300) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={3}
        playClicked={(id) => handlePlayClicked(seriesTypes.intervalSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(3)}
        name={"Intervallserie 1 (7s/3s)"}
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7 ||
               (elapsedTime >= 10 && elapsedTime < 17) ||
               (elapsedTime >= 20 && elapsedTime < 27) ||
               (elapsedTime >= 30 && elapsedTime < 37) ||
               (elapsedTime >= 40 && elapsedTime < 47)) {
              return "ACHTUNG"
            } else if ((elapsedTime > 7 && elapsedTime < 10) ||
                       (elapsedTime > 17 && elapsedTime < 20) ||
                       (elapsedTime > 27 && elapsedTime < 30) ||
                       (elapsedTime > 37 && elapsedTime < 40) ||
                       (elapsedTime > 47 && elapsedTime < 50)) {
              return "FEUER"
            } else if (elapsedTime >= 50) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if ((elapsedTime > 7 && elapsedTime < 10) ||
              (elapsedTime > 17 && elapsedTime < 20) ||
              (elapsedTime > 27 && elapsedTime < 30) ||
              (elapsedTime > 37 && elapsedTime < 40) ||
              (elapsedTime > 47 && elapsedTime < 50)) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={4}
        playClicked={(id) => handlePlayClicked(seriesTypes.intervalSeries, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(4)}
        name={"Intervallserie 2 (7s/3s)"}
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7 ||
              (elapsedTime >= 10 && elapsedTime < 17) ||
              (elapsedTime >= 20 && elapsedTime < 27) ||
              (elapsedTime >= 30 && elapsedTime < 37) ||
              (elapsedTime >= 40 && elapsedTime < 47)) {
              return "ACHTUNG"
            } else if ((elapsedTime > 7 && elapsedTime < 10) ||
                       (elapsedTime > 17 && elapsedTime < 20) ||
                       (elapsedTime > 27 && elapsedTime < 30) ||
                       (elapsedTime > 37 && elapsedTime < 40) ||
                       (elapsedTime > 47 && elapsedTime < 50)) {
              return "FEUER"
            } else if (elapsedTime >= 50) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if ((elapsedTime > 7 && elapsedTime < 10) ||
              (elapsedTime > 17 && elapsedTime < 20) ||
              (elapsedTime > 27 && elapsedTime < 30) ||
              (elapsedTime > 37 && elapsedTime < 40) ||
              (elapsedTime > 47 && elapsedTime < 50)) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={5}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries20, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(5)}
        name={"Zeitserie 1 (20s)"}
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 27) {
              return "FEUER"
            } else if (elapsedTime >= 27) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime > 7 && elapsedTime < 27) {
            return "green"
          } else {
            return "red"
          }
        })} />
      <SeriesItem
        id={6}
        playClicked={(id) => handlePlayClicked(seriesTypes.timeSeries10, id)}
        stopClicked={handleStopClicked}
        ongoing={isOngoing(6)}
        name={"Zeitserie 2 (10s)"}
        showLight
        showCommand={(elapsedTime) => {
            if (elapsedTime < 7) {
              return "ACHTUNG"
            } else if (elapsedTime >= 7 && elapsedTime < 17) {
              return "FEUER"
            } else if (elapsedTime >= 17) {
              return "FERTIG"
            }
          }
        }
        lightColor={(elapsedTime => {
          if (elapsedTime > 7 && elapsedTime < 17) {
            return "green"
          } else {
            return "red"
          }
        })} />
    </>
  );
};

export default BdsKombi
