import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const IconButton = ({ className, icon, onClick }) =>
  <div className={className} onClick={onClick}><FontAwesomeIcon icon={icon} /></div>

export default styled(IconButton)`
  cursor: pointer;
  padding: 0.5em;
`
