import React, { useState, useEffect } from 'react';
import IconButton from './buttons/IconButton'
import styled from 'styled-components'
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { useWindowHeight, useWindowWidth } from '../lib/hooks'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { start, stop } from '../features/counter/counterSlice'

const Light = styled.div`
  border-radius: 15px;
  width: 30px;
  height: 30px;
  background-color: ${props => props.lightColor || "red"};
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const Clock = ({id, lightColor, showTime, showLight, showCommand}) => {
    const counterState = useSelector((state) => state.counter[id]) || {}
    const { startedAt } = counterState
    const [currentCount, setCurrentCount] = useState(null);

    const width = useWindowWidth()
    const height = useWindowHeight() - 64 // 64 is the height of the navbar
    const widthOrHeight = width >= height ? height : width

    // TODO: automatically stop after maxValue
    // TODO: show seconds since start timestamp instead of adding seconds

    // console.log("startedAt!!", id, counterState)

  
    useEffect(
        () => {
            if (startedAt == null) {
                return;
            }
            
            const updateCount = () => {
              if (startedAt == null || startedAt == undefined) {
                // console.log("startedAt is null")
                setCurrentCount(null)
              } else {
                // console.log("updating count...", startedAt, moment())
                setCurrentCount(moment().diff(moment(startedAt)))
              }
            }
            setCurrentCount(0)
            const intervalId = setInterval(updateCount, 50);
            return () => {
              setCurrentCount(null)
              console.log("clearInterval called")
              clearInterval(intervalId)
            }
        },
        [startedAt]
    );

    if (startedAt == null) { 
      return null
    }

    // TODO: try to remove sound delay instead of compensating it
    const soundDelay = 800
    // get light color for current elapsed time in s
    const color = lightColor((currentCount - soundDelay) / 1000)

    const command = showCommand((currentCount - soundDelay) / 1000)

    return (
      <div>
        <div>
          {showTime && ((currentCount) / 1000).toFixed(1)}
        </div>
        { showLight && <Center><Light lightColor={color} /></Center> }
        { command && <Center><p>{command}</p></Center>}
      </div>
    )
};


const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
`

const PlayButton = ({ className, onClick }) =>
  <IconButton className={className} icon={faPlay} onClick={() => onClick('ClearButton')} />

const StopButton = ({ className, onClick }) =>
  <IconButton className={className} icon={faStop} onClick={() => onClick('ClearButton')} />


const StyledPlayButton = styled(PlayButton)`
background: ${props => props.theme.colors.bgPlayButton};
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledStopButton = styled(StopButton)`
  background: ${props => props.theme.colors.bgStopButton};
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemTitle = styled.div`
  font-size: 14pt;
  height: 60px;
  width: 15em;
  /* line-height: 60px;
  text-align: center;
  margin-left: 1em; */
  display: flex;
  justify-content: center;
  align-items: center;
`


export const SeriesItem = ({
    id, playClicked, stopClicked, ongoing, name, lightColor, showTime,
    showLight, showCommand
  }) => {

  const dispatch = useDispatch()


  const handleStopButtonClick = () => {
    dispatch(() => dispatch(stop({ id })))
    stopClicked(id)
  }
  const handlePlayButtonClick = () => {
    // TODO: dont dispatch start if other timer already started
    dispatch(() => dispatch(start({ id })))
    playClicked(id)
  }

  return (
    <>
      <ItemWrapper>
        { ongoing?
          <StyledStopButton onClick={handleStopButtonClick} />
          :
          <StyledPlayButton onClick={handlePlayButtonClick} />
        }
        <ItemTitle>{name}</ItemTitle>
      </ItemWrapper>
      
      { <Clock id={id} lightColor={lightColor} showTime={showTime} 
               showLight={showLight} showCommand={showCommand} /> }
    </>
  );
}

export default SeriesItem;
