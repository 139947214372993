import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    start: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { id } = action.payload
      return { ...state,
        [id]:  {
          startedAt: moment().format()
        }
      }
    },
    stop: (state, action) => {
      const { id } = action.payload
      return { ...state, 
        [id]:  {
          startedAt: null
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { start, stop } = counterSlice.actions

export default counterSlice.reducer