import React from 'react'
import styled from 'styled-components'
import { IconButton } from '../components/buttons'
import { faUndo } from '@fortawesome/free-solid-svg-icons'

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.bgNavbar};
  color: ${props => props.theme.colors.txtNavbar};
  box-shadow: 0 3px 3px -3px ${props => props.theme.colors.gray};
`

const PageTitle = styled.div`
  width: 90vw;
  padding-left: 10vw;
  font-size: 16pt;
  font-weight: bold;
`

const ItemsRight = styled.div`
  width: 10vw;
  display: flex;
  align-items: center;
  margin-left: auto;
  display: none;
`

const ClearButton = ({ className, onClick }) =>
  <IconButton className={className} icon={faUndo} onClick={() => onClick('ClearButton')} />

const Navbar = (props) => {
  const buttonClicked = () => console.log("ButtonClicked!")

  return (
    <NavWrapper>
      {/* <GoBackButton onClick={() => console.log("goBack()")} /> */}
      <PageTitle>BDS Timer</PageTitle>
      <ItemsRight>
        <ClearButton onClick={buttonClicked} />
        {/* <DotMenuButton onClick={buttonClicked} /> */}
      </ItemsRight>
    </NavWrapper>
  )
}

export default Navbar
