import React from 'react';
import stopCommand from './assets/Stop.wav'
import Sound from 'react-sound';
import Layout from './components/Layout'
import { DisciplineProvider } from './contexts/DisciplineContext'
import { AppContext } from './contexts/AppContext'
import BdsKombi from './components/disciplines/BdsKombi'
import BdsLwZeitserie from './components/disciplines/BdsLwZeitserie'
import { store } from './store/store'
import { Provider } from 'react-redux'
import './App.css';

const handleSongFinishedPlaying = (e) => {
  console.log("Finished Playing!")
}

const handleSongLoading = (e) => {
  console.log("Loading song...!")
}

const handleSongPlaying = (e) => {
  console.log("Playing song...!")
}

class App extends React.Component {
  static contextType = AppContext;

  state = {
    playStatus: Sound.status.STOP,
    command: stopCommand,
    timers: null,
    ongoingSeries: false,
    timeElapsed: 0
  }


  startCallSequence = (sequence) => {
    const { timers } = this.state
    const [,setAppState] = this.context

    if (timers !== null && timers !== undefined) {
      window.alert("Es läuft bereits ein Timer. Bitte zuerst stoppen, bevor eine neue gestartet werden kann.")
      return false
    } else {
      // Wenn noch keine laufen -> neue starten und Referenzen merken
      console.log(`Es laufen noch keine Timer. Starte timer...`)
      const startedTimers = sequence.map((interval) => {
        return setTimeout(() => this.setState({ playStatus: interval.playStatus, command: interval.command }), interval.delay)
      })
      console.log("Folgende Timer wurden gestartet: ", startedTimers)

      // Timer merken
      this.setState({ timers: startedTimers, ongoingSeries: true })
      // Update global app context
      setAppState({ongoingSeries: true})
      return true
    }
  }

  stopCalls = () => {
    const { timers } = this.state
    const [,setAppState] = this.context

    if (timers !== null && timers !== undefined) {
      console.log("Stoppe alle Timer")
      const stoppedTimers = timers.map(id => {
        clearTimeout(id)
        return id
      })
      //this.setState({timers: null, playStatus: Sound.status.STOP})
      console.log("Folgende Timer wurden gestoppt: ", stoppedTimers)
    } else {
      console.log("Es gab keine Timer zu stoppen")
    }

    // Update timer and player state
    this.setState({
      ...this.state,
      timers: null,
      ongoingSeries: false,
      playStatus: Sound.status.STOP
    })

    // Update global app context
    setAppState({ongoingSeries: false})
  }

  render = () => {
    const { playStatus, command } = this.state

    return (
      <Provider store={store}>
        <DisciplineProvider>
          <div className="App">
            <Layout>
              <BdsKombi
                startCalls={this.startCallSequence}
                stopCalls={this.stopCalls}
              />
              <BdsLwZeitserie
                startCalls={this.startCallSequence}
                stopCalls={this.stopCalls}
              />
            </Layout>
          </div>
          <Sound
            url={command}
            playStatus={playStatus}
            playFromPosition={0}
            onLoading={handleSongLoading}
            onPlaying={handleSongPlaying}
            onFinishedPlaying={handleSongFinishedPlaying}
          />
        </DisciplineProvider>
      </Provider>
    );
  }

}

export default App;
