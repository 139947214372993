import React, { useState, createContext } from 'react'

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [state, setState] = useState({
    ongoingSeries: false
  })

  const updateState = newState => {
    // merge previous and new state
    setState(prevState => {
      console.log("[AppContext]", {prevState, newState})
      return {...prevState, ...newState} })
  }

  return (
    <AppContext.Provider value={[state, updateState]}>
      { props.children }
    </AppContext.Provider>
  )
}

