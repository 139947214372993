import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import packageJson from '../../package.json';
import DisciplineChooser from './DisciplineChooser'

import {
  GlobalStyle,
  theme
} from '../styles'

import Navbar from './Navbar'

export const Container = styled.div`
  height: 100%;
  display: grid;
  padding-bottom: ${props => props.theme.geometry.footerHeight};    /* Footer height */
  grid-template-rows: 64px 5rem 1fr 64px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'subnav'
    'content'
    'footer';
  background-color: #fff;
`

const StyledDisciplineChooser = styled(DisciplineChooser)`
  grid-area: 'subnav';
  background-color: 'yellow';
`

export const Header = styled.header`
  height: 64px;

  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled.div`
  /* padding: 0 2em; */

  grid-area: content;
  overflow-y: auto;
`

const Footer = styled.footer`
  grid-area: footer;
  font-size: 8pt;

  height: ${props => props.theme.geometry.footerHeight};
  width: 100vw;
  position: absolute;
  bottom: 0;

  background-color: ${props => props.theme.colors.bgFooter};
  color: ${props => props.theme.colors.txtFooter};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'left middle right';
  justify-items:center;
  align-items: center;
`

const RightFooter = styled.div`
  grid-area: right;
`

const MiddleFooter = styled.div`
  grid-area: middle;
`

const LeftFooter = styled.div`
  grid-area: left;
`

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Container>
        <Header>
          <Navbar />
        </Header>
        <StyledDisciplineChooser />
        <Content>
          { children }
        </Content>
        <Footer>
          <LeftFooter>
            Copyright © 2022 B.Stiegel
          </LeftFooter>
          <MiddleFooter />
          <RightFooter>
            { `${packageJson.name} v${packageJson.version} (${process.env.NODE_ENV} build)` }
          </RightFooter>
        </Footer>
      </Container>
    </ThemeProvider>
  </>
)

export default Layout
