import React, { useContext } from 'react';
import styled from 'styled-components'
import { DisciplineContext } from '../contexts/DisciplineContext'
import { AppContext } from '../contexts/AppContext'

const Item = styled.li`
  display: block;
  background-color: ${props => props.selected? props.theme.colors.active : props.theme.colors.bgDisciplineItem};
  width: 100px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.active};
  padding: 0.4em;
  font-size: 12pt;
`

const HorizontalScrollWrapper = styled.ul`
  display: grid;
  grid-template-rows: 'minmax(150px, 1fr)';
  grid-template-columns: repeat(4, 110px);
  overflow-x: scroll;
  grid-gap: 10px;

  margin: 0px;
  padding: 0px;
  justify-items: center;
  align-items: center;
  height: 80px;
  scroll-snap-type: 'x proximity';
`

const ScrollContainer = styled.div`
  width: 100%;
  height: 80px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns:
    10px
    1fr
    10px;
  grid-template-rows: minmax(150px, 1fr);

  /* Hide Scroll */
  overflow-y: hidden;
  /* margin-bottom: calc(-.1 * var(--gutter)); */

  background-color: ${props => props.theme.colors.bgDisciplineChooser};
`

const PseudoDiv = styled.div`
  /* background-color: yellow; */
`

const DisciplineChooser = () => {
  const [disciplines, selectDiscipline] = useContext(DisciplineContext)
  const [appState,] = useContext(AppContext)

  const handleItemClicked = (id) => {
    // check if app is busy with ongoing other series
    if (appState.ongoingSeries) {
      window.alert("Bitte zuerst die Serie stoppen bevor die Disziplin gewechselt werden kann.")
    } else {
      selectDiscipline(id)
    }
  }

  return (
    <ScrollContainer>
      <PseudoDiv />
        <HorizontalScrollWrapper>
          { disciplines.map(d =>
            <Item key={d.id} selected={d.selected} onClick={() => handleItemClicked(d.id)}>
              {d.name}
            </Item>)
          }
        </HorizontalScrollWrapper>
      <PseudoDiv />
    </ScrollContainer>
  );
};

export default DisciplineChooser;
